import React from 'react';
import styles from './ShareButtons.module.scss';
import Helmet from 'react-helmet';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon,
} from 'react-share';


type Props = {
    shareTitle: string,
    articleUrl: string
};

const ShareButtons = ({ shareTitle, shareUrl }: Props) => (

<div className={styles['sharebuttons']}>

    <TwitterShareButton  className={styles['sharebuttons__twitter']} title={shareTitle + "\n"} via="kiotera_tech" url={shareUrl}>
        <TwitterIcon size={40} round />
    </TwitterShareButton>
    <FacebookShareButton className={styles['sharebuttons__facebook']} url={shareUrl} quote={shareTitle}>
        <FacebookIcon size={40} round />
    </FacebookShareButton>
    <EmailShareButton className={styles['sharebuttons__email']} subject={shareTitle} body={shareTitle　+ "\n"} url={shareUrl}>
        <EmailIcon size={40} round />
    </EmailShareButton>

    <Helmet>
    <script type="text/javascript" src="//b.st-hatena.com/js/bookmark_button.js" charset="utf-8" async="async" />
    </Helmet>
    <a
    href="http://b.hatena.ne.jp/entry/"
    className="hatena-bookmark-button"
    data-hatena-bookmark-layout="touch"
    data-hatena-bookmark-lang="ja"
    title="このエントリーをはてなブックマークに追加"
    >
    <img
        width="40"
        height="40"
        src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
        alt="このエントリーをはてなブックマークに追加"
        style={{border: 'none'}}
        />
    </a>

    
    </div>

);

export default ShareButtons;
