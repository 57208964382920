// @flow
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import ShareButtons from './ShareButtons';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import { useSiteMetadata } from '../../hooks';

type Props = {
  post: Node
};

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;
  const { url } = useSiteMetadata();
  const description = post.frontmatter.description;

  return (
    <div className={styles['post']}>
      <Helmet>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:image" content="https://kiotera-tech.com/kiotera-icon.png" />  {/* TODO: 適切な画像urlを設定する*/}
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description} />
      </Helmet>

      <Link className={styles['post__home-button']} to="/">All Articles</Link>

      <div className={styles['post__content']}>      
        <Content body={html} title={title} date={date} />
      </div>

      <div className={styles['post__footer']}>
        <ShareButtons shareTitle={title} shareUrl={url + slug}></ShareButtons>
        <br></br>
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}        
        <Author />
      </div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
